import { AxiosInstance } from 'axios'

import { Parking } from './parkings'

export interface OwnerDetails {
  firstName: string
  lastName: string
  email: string
  phone: string
  licensePlateNumber: string
  marketingAgreement: boolean
  lang: string
}

export interface InvoiceDetails {
  taxNumber: string
  companyName: string
  address: string
  zipCode: string
  city: string
}

export interface Subscription {
  ownerDetails: OwnerDetails
  invoiceDetails?: InvoiceDetails
  start: string
  end: string
  parkingUuid: string
  discountCodeUuid?: string
  planUuid?: string
  renewable?: boolean
  slotUuid?: string
  clientId?: string
}

export interface Reservation {
  ownerDetails: OwnerDetails
  invoiceDetails?: InvoiceDetails
  start: string
  planUuid: string
  parkingUuid: string
  discountCodeUuid?: string
  renewable?: boolean
  slotUuid?: string
  clientId?: string
}

export type CreateSubscriptionData = Subscription | Reservation

export interface CreateSubscriptionResponse {
  uuid: string
  paymentMethodResponses: [
    {
      name: string
      uuid: string
    }
  ]
}

export interface PatchSubscriptionData {
  operation: 'CANCEL'
}

export interface DetailsSubscriptionResponse {
  cancellable: boolean
  parkingUuid: string
}

export enum PaymentStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  REFUNDED = 'REFUNDED'
}

export interface PaymentDetailsSubscriptionResponse {
  status: PaymentStatus
  parkingUrlSlug?: string
}

export interface PatchSubscriptionParams {
  lang: string
}

export interface CreateSubscriptionParams {
  lang: string
}

export interface ColorScheme {
  accentFirst: string
  accentSecond: string
  contrast: string
  neutral: string
  main: string
  tint: string
  error: string
}

export interface CustomizedClientsResponse {
  logoUrl: string
  colorScheme: ColorScheme
  free: boolean
  parkings: {
    parking: Parking
    slotUuid: string
  }[]
}

export interface AvailabilitySubscriptionParams {
  parkingUuid?: string
  startDateTime?: string
  endDateTime?: string
  planUuid?: string
  slotUuid?: string
  plateNumber?: string
  plateCategory?: string
  countryStateCode?: string
  countryCode?: string
}

export interface AvailabilitySubscriptionResponse {
  available: boolean
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (axios: AxiosInstance) => ({
  details: (id: string) =>
    axios.get<DetailsSubscriptionResponse>(`/api/v1/subscriptions/${id}`),
  customizedClient: (id: string) =>
    axios.get<CustomizedClientsResponse>(
      `/api/v1/customized-clients/${id}/data`
    )
})
