import { css } from '@emotion/react'

import {
  colors,
  transitions,
  Weights,
  weights,
  gradients
} from '@/styles/theme'
import { mediaUp } from '@/styles/media'
import { fade, line } from '@/styles/global'

export interface StrongProps {
  strong?: boolean
  as?: unknown
}

export const withStrong = (weight: Weights) => (props: StrongProps) =>
  css`
    ${(props.strong || props.as === 'strong') &&
    `font-weight: ${weights[weight]};`}
  `

export const underline = css`
  text-decoration: underline;

  @supports (text-decoration-color: transparent) {
    text-decoration: underline;
    transition: text-decoration-color ${transitions.base};
    text-decoration-color: currentColor;
  }
`

export const underlineHover = css`
  &:hover {
    text-decoration: underline;
  }

  @supports (text-decoration-color: transparent) {
    text-decoration: underline;
    transition: text-decoration-color ${transitions.base};
    text-decoration-color: transparent;

    &:hover {
      text-decoration-color: currentColor;
    }
  }
`

export const spacingButton = css`
  padding: 8px 18px;

  ${mediaUp('lg')} {
    padding: 15px 40px;
  }
`

export const heightButton = css`
  height: 30px;
  ${spacingButton}

  ${mediaUp('lg')} {
    height: 55px;
  }
`

export const blueBox = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${colors.paleGrey};

  ${mediaUp('lg')} {
    border-radius: 28px;
  }
`

export const spacingSection = css`
  margin: 60px 0;

  ${mediaUp('lg')} {
    margin: 120px 0;
  }
`

export const spacingSmallSection = css`
  margin: 40px 0;

  ${mediaUp('lg')} {
    margin: 80px 0;
  }
`

export const resetInput = css`
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  outline: none;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button,
  &::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    display: none;
    margin: 0;
  }
`

export const visuallyHidden = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
`

export const focusVisibleStyles = css`
  outline: 2.5px dashed ${colors.cloudyBlue};
  transition: outline-width 0.1s ease;
  outline-offset: 3px;
`

export const focusVisible = css`
  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    ${focusVisibleStyles};
  }
`

export const titleAnimation = css`
  ${mediaUp('md')} {
    animation: ${fade} 1s both;
    animation-delay: 0.5s;

    strong::after {
      animation: ${line} 0.5s both cubic-bezier(0.77, 0, 0.175, 1);
      animation-delay: 0.75s;
      transform-origin: left;

      html[lang^='ar'] & {
        transform-origin: right;
      }
    }
  }
`

export const closeButton = css`
  ${focusVisible};
  cursor: pointer;
  position: absolute;
  width: 16px;
  height: 16px;

  ${mediaUp('lg')} {
    width: 24px;
    height: 24px;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 50%;
    top: 50%;
    border-radius: 5px;
    background-color: ${colors.twilightBlue};

    ${mediaUp('lg')} {
      height: 3px;
    }
  }

  &::after {
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }

  &::before {
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }
`

export const gradientApla = css`
  &:after {
    background: ${colors.paleGrey};
    background: ${gradients.heroMobile};

    ${mediaUp('md')} {
      background: ${gradients.hero};

      html[lang^='ar'] & {
        background: ${gradients.heroAR};
      }
    }
  }
`

export const imageBackgroundHero = css`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 50%;

  z-index: 1;

  animation: ${fade} 1s both;
  animation-delay: 0.5s;

  img {
    object-fit: cover;
    object-position: 50% 15%;

    ${mediaUp('md')} {
      object-position: 100% 0%;
    }
  }

  ${mediaUp('md')} {
    left: 34%;
    width: 66%;
    height: 100%;
  }

  html[lang^='ar'] & {
    right: 0;
    left: unset;

    img {
      transform: scaleX(-1);
    }

    ${mediaUp('md')} {
      right: 34%;
    }
  }
`
