import React, { useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { css } from '@emotion/react'

import { Params } from 'next/dist/shared/lib/router/utils/route-matcher'

import { useMarket } from '@/utils/multi-markets/context'
import { Market } from '@/utils/multi-markets/types'
import { Locale } from '@/utils/i18n/types'
import { colors, border, transitions } from '@/styles/theme'
import { mediaUp } from '@/styles/media'
import { H3Styles, Text2Styles } from '@/styles/typography'

import Modal from '@/components/common/Modal'
import Button from '@/components/common/Button'
import FlagPLIcon from '@/assets/flagPL.svg?inline'
import FlagAEIcon from '@/assets/flagAE.svg?inline'
import FlagBHIcon from '@/assets/flagBH.svg?inline'
import {
  dynamicRouteFromQuery,
  i18nParamsForLocale,
  toAbsoluteURL
} from '@/utils/multi-markets/common'
import { useI18nParams } from '@/utils/i18n/i18n-params-context'
import { useFetchCustomizedClients } from '@/service/apiHooks/useFetchCustomizedClients'

interface Props {
  show: boolean
  onClose(): void
}

function LangLink(props: Props) {
  const { t } = useTranslation('common')
  const { show = false, onClose } = props
  const router = useRouter()
  const { market, locale, markets } = useMarket()
  const { i18nParams } = useI18nParams()

  const [currentLang, setCurrentLang] = useState<Locale>(locale)
  const [currentMarket, setCurrentMarket] = useState<Market>(market)
  const [loading, setLoading] = useState(false)
  const { id } = router.query as Params
  const { clientDetails } = useFetchCustomizedClients({
    id
  })

  const hideLangs = router.pathname === '/intermobility-expo'
  const isLegia = router.pathname === '/legia'

  useEffect(() => {
    if (!show) {
      setCurrentLang(locale)
      setCurrentMarket(market)
      setLoading(false)
    }
  }, [show])

  const changeLang = (lang: Locale) => {
    setCurrentLang(lang)
  }

  const changeMarket = (region: Market) => {
    if (currentLang === 'ar' && region === 'pl') {
      changeLang('en')
    }
    setCurrentMarket(region)
  }

  const saveLangSettings = () => {
    const { pathname } = router
    setLoading(true)
    const { pathname: absoluteUrl, query } = dynamicRouteFromQuery(
      toAbsoluteURL(pathname, currentLang, markets[currentMarket]),
      i18nParamsForLocale(router.query, i18nParams, currentLang)
    )
    const absoluteQuery =
      query && Object.keys(query).length
        ? '?' + new URLSearchParams(query as string).toString()
        : ''
    const x = i18nParamsForLocale(router.query, i18nParams, currentLang)
    window.location.assign(absoluteUrl + absoluteQuery)
  }

  const countries = (
    <>
      <div css={label}>{t('languageModal.countryLabel')}</div>
      <button
        css={[pickButton, currentMarket === 'pl' && pickButtonActive]}
        onClick={() => changeMarket('pl')}
      >
        <FlagPLIcon css={flag} />
        <div css={country}>{t('languageModal.countryPL')}&nbsp;</div>
        <div css={currency}>PLN</div>
      </button>
      <button
        css={[pickButton, currentMarket === 'ae' && pickButtonActive]}
        onClick={() => changeMarket('ae')}
      >
        <FlagAEIcon css={flag} />
        <div css={country}>{t('languageModal.countryAE')}&nbsp;</div>
        <div css={currency}>AED</div>
      </button>
      <button
        css={[pickButton, currentMarket === 'bh' && pickButtonActive]}
        onClick={() => changeMarket('bh')}
      >
        <FlagBHIcon css={flag} />
        <div css={country}>{t('languageModal.countryBH')}&nbsp;</div>
        <div css={currency}>BHD</div>
      </button>
    </>
  )

  const langs = (
    <>
      <div css={label}>{t('languageModal.languageLabel')}</div>
      {!hideLangs && (
        <button
          css={[pickButton, currentLang === 'pl' && pickButtonActive]}
          onClick={() => changeLang('pl')}
        >
          <div css={country}>Polski</div>
        </button>
      )}
      <button
        css={[pickButton, currentLang === 'en' && pickButtonActive]}
        onClick={() => changeLang('en')}
      >
        <div css={country}>English</div>
      </button>
      {!hideLangs && (
        <>
          <button
            css={[pickButton, currentLang === 'de' && pickButtonActive]}
            onClick={() => changeLang('de')}
          >
            <div css={country}>Deutsch</div>
          </button>
          {/* <button
            css={[
              pickButton,
              currentLang === 'ar' && pickButtonActive,
              arButton
            ]}
            onClick={() => changeLang('ar')}
            disabled={currentMarket === 'pl'}
          >
            <div css={[country]}>عربي</div>
          </button> */}
        </>
      )}
    </>
  )

  return (
    <Modal onClose={onClose} show={show}>
      <div css={content}>
        {!clientDetails?.free && !isLegia && <div css={row}>{countries}</div>}
        <div css={row}>{langs}</div>
      </div>
      <Button
        text={t('languageModal.save')}
        css={buttonWrapper}
        onClick={saveLangSettings}
        loading={loading}
      />
    </Modal>
  )
}

const content = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: -18px;

  ${mediaUp('lg')} {
    margin-bottom: -40px;
  }
`

const row = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &:first-of-type {
    margin-bottom: 20px;

    ${mediaUp('lg')} {
      margin-bottom: 20px;
    }
  }
`

const label = css`
  ${H3Styles};
  width: 100%;
  margin-bottom: 20px;

  ${mediaUp('lg')} {
    margin-bottom: 40px;
  }
`

const pickButton = css`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  border-radius: 28px;
  box-shadow: ${border.shadow};
  background-color: ${colors.white};
  transition: background-color ${transitions.base};
  border: 2px solid transparent;
  padding: 0 18px;
  height: 30px;
  width: 100%;

  ${mediaUp('md')} {
    width: calc(50% - 10px);
  }

  ${mediaUp('lg')} {
    margin-bottom: 40px;
    padding: 0 40px;
    height: 55px;
  }

  &[disabled] {
    background-color: ${colors.cloudyBlue};
  }
`

const pickButtonActive = css`
  border-color: ${colors.darkSkyBlue};
  background-color: ${colors.paleGrey};
`

const flag = css`
  margin-inline-end: 10px;
  width: 21px;
  height: 14px;

  ${mediaUp('lg')} {
    margin-inline-end: 18px;
    width: 32px;
    height: 21px;
  }
`

const country = css`
  ${Text2Styles};
  color: ${colors.twilightBlue};
  transition: color ${transitions.base};

  [disabled] & {
    color: ${colors.white};
  }
`

const currency = css`
  ${Text2Styles};
`

const buttonWrapper = css`
  margin: 40px 0 auto;
  width: 100%;

  ${mediaUp('lg')} {
    width: auto;
    margin: 80px auto 0;
  }
`

const arButton = css`
  justify-content: end;
`

export default LangLink
