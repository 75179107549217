import { AxiosInstance } from 'axios'

export interface Payment {
  paymentMethodUuid: string
  entityType: 'SUBSCRIPTION'
  entityUuid: string
}

export type CreatePaymentData = Payment

export interface CreatePaymentResponse {
  webviewUrl: string
}

export interface CreatePaymentParams {
  lang: string
}

export default (axios: AxiosInstance) => ({
  create: (data: CreatePaymentData, params: CreatePaymentParams) =>
    axios.post<CreatePaymentResponse>('/api/v2/payments', data, { params })
})
