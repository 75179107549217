import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { css } from '@emotion/react'

import { useMarket } from '@/utils/multi-markets/context'
import { colors, weights, vars } from '@/styles/theme'
import { mediaDown, mediaUp } from '@/styles/media'
import { Text2Styles } from '@/styles/typography'
import { focusVisible, underlineHover } from '@/styles/mixins'

import LangModal from '@/components/common/LangModal'
import LangIcon from '@/assets/lang.svg?inline'
import SmallDropdownIcon from '@/assets/small-dropdown.svg?inline'

interface Props {
  short?: boolean
  showOnMobileNavbar?: boolean
}

function LangLink(props: Props) {
  const { t } = useTranslation('common')
  const { short = false, showOnMobileNavbar = false } = props
  const [showModal, setShowModal] = useState(false)
  const { locale, market } = useMarket()
  const localeText = locale.toUpperCase()
  const marketName = {
    pl: t('languageModal.countryPL'),
    ae: t('languageModal.countryAE'),
    bh: t('languageModal.countryBH')
  }[market]

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        css={[link, !showOnMobileNavbar && linkShowOnMobileNavbar]}
      >
        <LangIcon />
        <span css={text}>
          {short ? localeText : `${marketName} - ${localeText}`}
        </span>
        {!showOnMobileNavbar && <SmallDropdownIcon css={iconDropdown} />}
      </button>
      <LangModal onClose={() => setShowModal(false)} show={showModal} />
    </>
  )
}

const link = css`
  ${focusVisible};
  ${Text2Styles};
  ${underlineHover};
  color: var(--main);
  display: flex;
  align-items: center;

  svg {
    fill: var(--main);
    width: 16px;
    height: 16px;
  }
`

const linkShowOnMobileNavbar = css`
  ${mediaDown('lg')} {
    font-weight: ${weights.black};
    line-height: 1;
    color: ${colors.white};
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translate(-50%, 0);
    background: ${vars.whiteOpacity};
    border-radius: 16px;
    width: 348px;
    justify-content: center;
    height: 30px;

    svg {
      fill: ${colors.white};
      width: 10px;
      height: 10px;
    }
  }

  ${mediaDown('md')} {
    width: calc(100% - 32px);
  }
`

const text = css`
  margin-inline-start: 8px;
`

const iconDropdown = css`
  margin-inline-start: 18px;

  ${mediaUp('lg')} {
    display: none;
  }
`

export default LangLink
