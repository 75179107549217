import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'

import { Colors, colors } from '@/styles/theme'
import { mediaUp } from '@/styles/media'

type Size = 'small' | 'medium' | 'large'

const ring = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const loaderSmall = css`
  width: 10px;
  height: 10px;

  ${mediaUp('lg')} {
    width: 16px;
    height: 16px;
  }

  &::after {
    border-width: 1px;

    ${mediaUp('lg')} {
      border-width: 2px;
    }
  }
`

export const loaderMedium = css`
  width: 14px;
  height: 14px;

  ${mediaUp('lg')} {
    width: 23px;
    height: 23px;
  }

  &::after {
    border-width: 2px;

    ${mediaUp('lg')} {
      border-width: 3px;
    }
  }
`

export const loaderLarge = css`
  width: 32px;
  height: 32px;

  ${mediaUp('lg')} {
    width: 60px;
    height: 60px;
  }

  &::after {
    border-width: 3px;

    ${mediaUp('lg')} {
      border-width: 6px;
    }
  }
`

export const Loader = styled.span<{
  color: Colors
  size: Size
  center: boolean
}>`
  display: block;
  ${props =>
    props.center &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}

  ${props => props.size === 'small' && loaderSmall}
  ${props => props.size === 'medium' && loaderMedium}
  ${props => props.size === 'large' && loaderLarge}

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-style: solid;
    border-color: ${props =>
      `${colors[props.color]} transparent ${colors[props.color]} transparent;`}
    animation: ${ring} 1.2s linear infinite;
  }
`
