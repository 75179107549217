import React, { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import parse from 'html-react-parser'
import { useTranslation } from 'next-i18next'

import { AxiosError } from 'axios'

import { css } from '@emotion/react'

import Error from '@/components/form/Error'
import { first } from '@/utils/common'

import { SubscribeData } from '@/service/newsletter'
import Input from '@/components/form/Input'
import Button from '@/components/common/Button'
import api from '@/service'
import Title from '@/components/common/Title'

import { mediaUp } from '@/styles/media'
import { Text2 } from '@/styles/typography'

function Newsletter() {
  const { t, i18n } = useTranslation('common')
  const [sended, setSended] = useState(false)
  const [loading, setLoading] = useState(false)
  const form = useForm<SubscribeData>()
  const [error, setError] = useState('')

  const onSubmit: SubmitHandler<SubscribeData> = data => {
    if (loading) return
    setLoading(true)

    const payload = {
      ...data,
      lang: i18n.language
    }

    api.newsletter
      .subscribe(payload)
      .then(() => {
        setSended(true)
      })
      .catch(err => {
        const response = err.response as AxiosError['response']
        //@ts-ignore
        const error = first(response?.data?.errors) || t('newsletter.error')
        setError(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      {!sended && (
        <form css={styles.container} onSubmit={form.handleSubmit(onSubmit)}>
          <Title text={t('newsletter.title')} />
          <Text2 css={styles.description}>
            {parse(t('newsletter.description'))}
          </Text2>
          <Controller
            control={form.control}
            name="email"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: t('form.errors.required')
              },
              pattern: {
                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                message: t('form.errors.email')
              }
            }}
            render={({ field, fieldState }) => (
              <Input
                hideLabel
                label={t('newsletter.placeholder')}
                placeholder={t('newsletter.placeholder')}
                value={field.value}
                error={fieldState.error?.message}
                disabled={loading}
                onChange={field.onChange}
                onBlur={field.onBlur}
              >
                <div css={styles.buttonWrapper}>
                  <Button
                    type="submit"
                    text={t('newsletter.button')}
                    loading={loading}
                    disabled={loading}
                  />
                </div>
              </Input>
            )}
          />
          {error && <Error error={error} className="error--newsletter" />}
        </form>
      )}
      {sended && (
        <form css={styles.container}>
          <Title css={styles.thanksTitle} text={t('newsletter.thanks')} />
        </form>
      )}
    </>
  )
}

const styles = {
  container: css`
    margin: 40px 0 0;

    ${mediaUp('lg')} {
      margin: 80px 0 20px;
    }

    h2 {
      ${mediaUp('md')} {
        text-align: start;
      }
    }

    input {
      padding-inline-end: 100px;

      ${mediaUp('lg')} {
        padding-inline-end: 205px;
      }
    }

    .error--newsletter {
      padding-inline-start: 0;
      text-align: center;
      margin: -9px 0 15px;

      ${mediaUp('lg')} {
        margin: -20px 0 15px;
      }
    }
  `,
  description: css`
    margin: 18px 0;
    text-align: center;

    ${mediaUp('md')} {
      text-align: start;
    }

    ${mediaUp('lg')} {
      margin: 20px 0;
    }
  `,
  buttonWrapper: css`
    position: absolute;
    top: 0;
    right: 0;

    html[lang^='ar'] & {
      left: 0;
      right: unset;
    }
  `,
  thanksTitle: css`
    margin-bottom: 20px;

    ${mediaUp('md')} {
      margin-bottom: 0;
    }
  `
}

export default Newsletter
