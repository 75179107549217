import { AxiosInstance } from 'axios'

export interface ContactForm {
  name: string
  email: string
  phone: string
  content: string
  attachmentName: string
  label: string
}

export type PostContactData = ContactForm

export interface PostContactResponse {
  webviewUrl: string
}

export interface AttachmentContactData {
  file: File
}

export interface AttachmentContactResponse {
  attachmentName: string
}
export interface LandingForm {
  name?: string
  email: string
  phone?: string
}

export type LandingData = LandingForm

export default (axios: AxiosInstance) => ({
  post: (data: PostContactData) =>
    axios.post<PostContactResponse>('/api/v1/contacts', data),
  attachment: (data: FormData) =>
    axios.post<AttachmentContactResponse>(
      '/api/v1/contacts/attachments',
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    ),
  landing: (data: LandingData) => axios.post('/api/v1/contacts/blitz', data)
})
