import React, { useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import html from 'html-react-parser'
import Head from 'next/head'

import { css } from '@emotion/react'

import { Params } from 'next/dist/shared/lib/router/utils/route-matcher'

import { useRouter } from 'next/router'

import Link from '@/components/common/Link'

import { Grid, mediaColumns } from '@/styles/grid'
import { Text1, Text2, Link1 } from '@/styles/typography'
import { mediaUp, mediaDown } from '@/styles/media'

import Newsletter from '@/components/sections/Newsletter'
import LangLink from '@/components/common/LangLink'
import { useMarket } from '@/utils/multi-markets/context'
import { DOCUMENTS } from '@/constants'

import { DEFAULT_COLORS } from '@/constants'
import { setCssVars } from '@/utils/common'
import { useFetchCustomizedClients } from '@/service/apiHooks/useFetchCustomizedClients'

const AeCopyrightsText = <>{html('NaviParking DMCC')}</>
const PlCopyrightsText = <>{html('Naviparking Poland Sp. z o.o.')}</>

interface Props {
  short?: boolean
}

const components = {
  bh: AeCopyrightsText,
  ae: AeCopyrightsText,
  pl: PlCopyrightsText
}

function Footer(props: Props) {
  const { t } = useTranslation('common')
  const { short = false } = props
  const footerDate = new Date()
  const { locale, market } = useMarket()
  const CopyrightsText = components[market]
  const router = useRouter()
  const { id } = router.query as Params
  const { clientDetails } = useFetchCustomizedClients({
    id
  })

  useEffect(() => {
    const colors = clientDetails?.colorScheme
      ? clientDetails?.colorScheme
      : DEFAULT_COLORS

    if (colors) {
      setCssVars(colors)
    }
  }, [])

  return (
    <>
      <Head>
        <script
          id="CookieConsent"
          src="https://policy.app.cookieinformation.com/uc.js"
          data-culture={locale?.toUpperCase()}
          data-gcm-version="2.0"
          type="text/javascript"
          data-default-consent="true"
          async
        />
      </Head>
      <footer css={container}>
        {!short && (
          <Grid>
            <div css={newsletterContainer} data-test-id="footer-newsletter">
              <Newsletter />
            </div>

            <nav css={navigationContainer}>
              <div css={navigationGroup} data-test-id="footer-portal">
                <Text1 css={navigationGroupTitle} as="strong">
                  {t('footer.portal')}
                </Text1>
                <ul css={linksList}>
                  <li>
                    <Link
                      href={{ pathname: '/parkings' }}
                      passHref
                      prefetch={false}
                    >
                      <Link1>{t('navigation.search')}</Link1>
                    </Link>
                  </li>

                  <li>
                    <Link
                      href={{ pathname: '/navipay' }}
                      passHref
                      prefetch={false}
                    >
                      <Link1>{t('navigation.navipay')}</Link1>
                    </Link>
                  </li>

                  <li>
                    <Link href={{ pathname: '/faq' }} passHref prefetch={false}>
                      <Link1>{t('navigation.faq')}</Link1>
                    </Link>
                  </li>

                  <li>
                    <Link
                      href={{ pathname: '/case-studies' }}
                      passHref
                      prefetch={false}
                    >
                      <Link1>{t('navigation.landings')}</Link1>
                    </Link>
                  </li>

                  <li>
                    <Link
                      href={`https://blog.digitalparking.city/${
                        locale === 'pl' ? locale : ''
                      }`}
                      passHref
                      prefetch={false}
                    >
                      <Link1 target="_blank">{t('navigation.blog')}</Link1>
                    </Link>
                  </li>

                  <li>
                    <Link
                      href={{ pathname: '/contact' }}
                      passHref
                      prefetch={false}
                    >
                      <Link1>{t('navigation.contact')}</Link1>
                    </Link>
                  </li>
                </ul>
              </div>

              <div css={navigationGroup} data-test-id="footer-community">
                <Text1 css={navigationGroupTitle} as="strong">
                  {t('footer.community')}
                </Text1>
                <ul css={linksList}>
                  <li>
                    <Link1
                      href={t('socials.facebook')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('footer.facebook')}
                    </Link1>
                  </li>

                  <li>
                    <Link1
                      href={t('socials.linkedin')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('footer.linkedin')}
                    </Link1>
                  </li>

                  <li>
                    <Link1
                      href={t('socials.twitter')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('footer.twitter')}
                    </Link1>
                  </li>

                  <li>
                    <Link1
                      href={
                        market === 'pl'
                          ? t('socials.instagram')
                          : 'https://www.instagram.com/naviparking/'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('footer.instagram')}
                    </Link1>
                  </li>
                </ul>
              </div>

              <div css={navigationGroup} data-test-id="footer-terms">
                <Text1 css={navigationGroupTitle} as="strong">
                  {t('footer.terms')}
                </Text1>
                <ul css={linksList}>
                  <li>
                    <Link1
                      target="_blank"
                      href={DOCUMENTS.terms[market][locale]}
                    >
                      {t('footer.termsOfUse')}
                    </Link1>
                  </li>

                  <li>
                    <Link1
                      target="_blank"
                      href={DOCUMENTS.privacyPolicy[market][locale]}
                    >
                      {t('footer.privacyPolicy')}
                    </Link1>
                  </li>
                </ul>
              </div>
            </nav>
          </Grid>
        )}
        <Grid>
          {router?.pathname?.includes('legia') && (
            <div
              css={copyrightsContainer}
              style={{ marginBottom: 0, marginTop: 20 }}
            >
              <Link1
                href={
                  'https://cdn.naviparking.com/docs/regulamin-konkursu-legia.docx.pdf'
                }
              >
                <Text2 css={copyrightsText}>Regulamin Konkursu Legia</Text2>
              </Link1>
            </div>
          )}
          <div css={divider} />
          <div css={copyrightsContainer}>
            <Text2 css={copyrightsText}>
              © {footerDate.getFullYear()} {CopyrightsText}
            </Text2>
            <LangLink short />
          </div>
        </Grid>
      </footer>
    </>
  )
}

const container = css`
  flex-shrink: 0;
  background-color: var(--tint);
`

const divider = css`
  ${mediaColumns({ xs: [12, 0] })};

  height: 2px;
  width: 100%;
  background-color: var(--main);

  opacity: 0.08;
  margin-top: 40px;

  ${mediaUp('lg')} {
    margin-top: 60px;
  }
`

const newsletterContainer = css`
  ${mediaColumns({ xs: [12, 0], md: [4, 0], lg: [5, 0] })};
  max-width: 282px;
  margin: 0 auto;

  br {
    display: block;
  }

  ${mediaUp('md')} {
    br {
      display: none;
    }

    min-width: 230px;
  }

  ${mediaUp('lg')} {
    max-width: 470px;
    min-width: unset;
  }
`

const navigationContainer = css`
  ${mediaColumns({ xs: [12, 0], md: [8, 4], lg: [7, 5] })};

  display: flex;
  flex-wrap: wrap;
  padding: 12px 16px;

  ${mediaUp('md')} {
    padding: 0 20px;
    margin-top: 40px;
  }

  ${mediaUp('lg')} {
    padding-inline-end: 0;
    margin: 80px 0 20px;
  }
`

const navigationGroup = css`
  width: 50%;
  margin-top: 18px;

  ${mediaUp('md')} {
    margin-top: 0;
    width: 33.33%;
  }
`

const navigationGroupTitle = css`
  display: inline-flex;
  margin-bottom: 10px;

  ${mediaUp('lg')} {
    margin-bottom: 20px;
  }
`

const linksList = css`
  list-style: none;

  li:not(:last-child) {
    ${mediaUp('lg')} {
      margin-bottom: 12px;
    }
  }

  a {
    display: inline-block;
    padding: 2px 0;

    ${mediaUp('lg')} {
      padding: 0;
    }
  }
`

const copyrightsContainer = css`
  ${mediaColumns({ xs: [12, 0] })};
  margin-bottom: 40px;

  ${mediaDown('lg')} {
    button {
      display: none;
    }
  }

  ${mediaUp('lg')} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 60px;
  }
`

const copyrightsText = css`
  text-align: center;
  color: var(--main);

  margin-top: 8px;

  ${mediaUp('lg')} {
    margin-top: 22px;
  }
`

export default Footer
