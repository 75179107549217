import { ReactNode } from 'react'
import html from 'html-react-parser'

import { unOrphan } from '@/utils/orphans'

import { PrimaryTitle, SecondaryTitle } from './Title.styled'

interface Props {
  text: string | ReactNode
  primary?: boolean
  animated?: boolean
  light?: boolean
  className?: string
  id?: string
  highlight?: boolean
  style?: any
}

function Title(props: Props) {
  const { text, primary, light, animated, className, id, highlight, style } =
    props
  const TitleComponent = primary ? PrimaryTitle : SecondaryTitle

  const normalizeLabel = (label: string | ReactNode) => {
    return typeof label === 'string' ? html(unOrphan(label)) : label
  }

  return (
    <TitleComponent
      light={light}
      primary={primary}
      animated={animated}
      className={className}
      highlight={highlight}
      id={id}
      style={style}
    >
      {normalizeLabel(text)}
    </TitleComponent>
  )
}

export default Title
