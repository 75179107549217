import { colors } from '@/styles/theme'
import { ColorScheme } from '@/service'

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const ACCOUNT_REGEX =
  /^\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}/

export const DOCUMENTS = {
  privacyPolicy: {
    pl: {
      pl: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/PL_Privacy_policy_cyfrowyparking.pl_naviparking.com_scantopay.com_2024_02_20.docx',
      en: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/EN_Privacy_policy_cyfrowyparking.pl_naviparking.com_scantopay.com_2024_02_20.docx',
      de: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/EN_Privacy_policy_cyfrowyparking.pl_naviparking.com_scantopay.com_2024_02_20.docx',
      ar: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/EN_Privacy_policy_cyfrowyparking.pl_naviparking.com_scantopay.com_2024_02_20.docx'
    },
    ae: {
      pl: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG_UAE_Privacy_Policy_28.07.2022.pdf',
      en: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG_UAE_Privacy_Policy_28.07.2022.pdf',
      de: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG_UAE_Privacy_Policy_28.07.2022.pdf',
      ar: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG_UAE_Privacy_Policy_28.07.2022.pdf'
    },
    bh: {
      pl: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG_UAE_Privacy_Policy_28.07.2022.pdf',
      en: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG_UAE_Privacy_Policy_28.07.2022.pdf',
      de: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG_UAE_Privacy_Policy_28.07.2022.pdf',
      ar: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG_UAE_Privacy_Policy_28.07.2022.pdf'
    }
  },
  terms: {
    pl: {
      pl: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/Regulamin+Cyfrowyparking.pl+10.11.2021.pdf',
      en: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG+Terms+%26+conditions+digitalparking.city+11.07.2023.pdf',
      de: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG+Terms+%26+conditions+digitalparking.city+11.07.2023.pdf',
      ar: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG+Terms+%26+conditions+digitalparking.city+11.07.2023.pdf'
    },
    ae: {
      pl: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG+UAE+Terms+%26+conditions+digitalparking.city+09.02.2024.pdf',
      en: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG+UAE+Terms+%26+conditions+digitalparking.city+09.02.2024.pdf',
      de: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG+UAE+Terms+%26+conditions+digitalparking.city+09.02.2024.pdf',
      ar: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG+UAE+Terms+%26+conditions+digitalparking.city+09.02.2024.pdf'
    },
    bh: {
      pl: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG+UAE+Terms+%26+conditions+digitalparking.city+09.02.2024.pdf',
      en: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG+UAE+Terms+%26+conditions+digitalparking.city+09.02.2024.pdf',
      de: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG+UAE+Terms+%26+conditions+digitalparking.city+09.02.2024.pdf',
      ar: 'https://cdn.naviparking.com/digital_parking/terms_and_conditions/ENG+UAE+Terms+%26+conditions+digitalparking.city+09.02.2024.pdf'
    }
  }
}

export const MOVENPICK_JBR = {
  cityUrlSlugs: { de: 'dubai', en: 'dubai', pl: 'dubaj', ar: 'dubai' },
  urlSlug: 'movenpick-jbr'
}

export const LEGIA_THEME: { logoUrl: string; colorScheme: ColorScheme } = {
  logoUrl:
    'https://digital-parking-data.s3.me-south-1.amazonaws.com/customizedClients/logos/wolaCenter.png',
  colorScheme: {
    accentFirst: '#39c349',
    accentSecond: '#ebebeb',
    contrast: '#ffffff',
    neutral: '#000000',
    main: '#000000',
    error: '#ce181e',
    tint: '#f5f5f5'
  }
}

export const DEFAULT_COLORS: ColorScheme = {
  main: colors.twilightBlue,
  accentFirst: colors.darkSkyBlue,
  tint: colors.paleGrey,
  neutral: colors.blueyGrey,
  accentSecond: colors.greenishCyan,
  contrast: colors.white,
  error: colors.darkCoral
}

export const GOOGLE_APP =
  'https://play.google.com/store/apps/details?id=eu.ekinnolab.navipay'
export const APPLE_APP = 'https://apps.apple.com/pl/app/navipay/id1455350141'
