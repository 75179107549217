import axios from 'axios'

import getConfig from 'next/config'

import { v4 as uuidv4 } from 'uuid'

import parkings from '@/service/parkings'
import parkingsInternal from '@/service/parkingsInternal'
import subscriptions from '@/service/subscriptions'
import subscriptionsInternal from '@/service/subscriptionsInternal'
import paymentsInternal from '@/service/paymentsInternal'
import contact from '@/service/contact'
import newsletter from '@/service/newsletter'
import { Faq } from '@/service/faq'
import { FaqInternal } from '@/service/faqInternal'
import discounts from '@/service/discounts'
import logger from '@/service/logger'
import blockings from '@/service/blockings'

const {
  publicRuntimeConfig: { API_URL }
} = getConfig()

export const instance = axios.create({
  baseURL: API_URL
})

export const internalInstance = axios.create({
  baseURL: '/'
})

instance.interceptors.request.use(config => {
  config.headers['X-Trace-Id'] = uuidv4()
  return config
})

internalInstance.interceptors.request.use(config => {
  config.headers['X-Trace-Id'] = uuidv4()
  return config
})

const api = {
  parkings: parkings(axios),
  parkingsInternal: parkingsInternal(axios),
  subscriptions: subscriptions(axios),
  subscriptionsInternal: subscriptionsInternal(axios),
  paymentsInternal: paymentsInternal(axios),
  contact: contact(instance),
  newsletter: newsletter(axios),
  faq: Faq(axios),
  faqInternal: FaqInternal(axios),
  discounts: discounts(axios),
  logger: logger(axios),
  blockings: blockings(axios)
}

export default api
export * from '@/service/parkings'
export * from '@/service/parkingsInternal'
export * from '@/service/subscriptions'
export * from '@/service/subscriptionsInternal'
export * from '@/service/paymentsInternal'
export * from '@/service/contact'
export * from '@/service/newsletter'
export * from '@/service/faq'
export * from '@/service/faqInternal'
export * from '@/service/discounts'
export * from '@/service/logger'
export * from '@/service/blockings'
