import styled from '@emotion/styled'

import { Text2Styles } from '@/styles/typography'
import { colors } from '@/styles/theme'

export const Wrapper = styled.div`
  ${Text2Styles};
  color: ${colors.darkCoral};
  margin-top: 10px;
  padding-inline-start: 10px;
  width: 100%;
`
