import { AxiosInstance } from 'axios'

import {
  ParkingsBySlugParams,
  ParkingsBySlugResponse,
  ParkingsPlanParams,
  ParkingsPlanResponse
} from './parkings'

// eslint-disable-next-line import/no-anonymous-default-export
export default (axios: AxiosInstance) => ({
  bySlug: (slug: string, params: ParkingsBySlugParams) =>
    axios.get<ParkingsBySlugResponse>(`/api/v1/parkings/${slug}`, { params }),
  plan: (slug: string, params: ParkingsPlanParams) =>
    axios.get<ParkingsPlanResponse>(`/api/v2/parkings/${slug}/plans`, {
      params
    })
})
