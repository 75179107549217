import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { colors, weights, fonts } from '@/styles/theme'
import {
  focusVisible,
  underlineHover,
  visuallyHidden,
  withStrong
} from '@/styles/mixins'

import { mediaUp, mediaDown } from './media'

export const FontFamilyStyles = css`
  font-family: ${fonts.primary};

  html[lang^='ar'] & {
    font-family: ${fonts.arabic};
  }
`

export const H1Styles = css`
  font-weight: ${weights.black};
  color: ${colors.twilightBlue};
  font-size: 24px;
  line-height: 1.29;

  ${mediaUp('lg')} {
    font-size: 48px;
    line-height: 1.27;
  }
`

export const H1 = styled.h1`
  ${H1Styles};
`

export const H2Styles = css`
  font-weight: ${weights.black};
  color: var(--main);
  font-size: 18px;
  line-height: 1.44;

  ${mediaUp('lg')} {
    font-size: 36px;
    line-height: 1.33;
  }
`

export const H2 = styled.h2`
  ${H2Styles};
`

export const H3Styles = css`
  font-weight: ${weights.black};
  color: ${colors.twilightBlue};
  font-size: 12px;
  line-height: 1.5;

  ${mediaUp('lg')} {
    font-size: 21px;
    line-height: 1.33;
  }
`

export const H3 = styled.h3`
  ${H3Styles};
`

export const Text1Styles = css`
  font-weight: ${weights.semibold};
  color: ${colors.twilightBlue};
  font-size: 12px;
  line-height: 1.5;

  ${mediaUp('lg')} {
    font-size: 21px;
    line-height: 1.33;
  }
`

export const Text1 = styled.p`
  ${Text1Styles};

  ${withStrong('black')};
`

export const Text2Styles = css`
  font-weight: ${weights.semibold};
  color: #6c7075;
  font-size: 10px;
  line-height: 1.4;

  ${mediaUp('lg')} {
    font-size: 18px;
    line-height: 1.56;
  }
`

export const InputTextStyles = css`
  ${Text2Styles};

  &::-webkit-input-placeholder {
    color: ${colors.cloudyBlue};
  }

  &::-moz-placeholder {
    color: ${colors.cloudyBlue};
  }

  &:-ms-input-placeholder {
    color: ${colors.cloudyBlue};
  }

  &:-moz-placeholder {
    color: ${colors.cloudyBlue};
  }
`

export const ButtonTextStyles = css`
  ${Text2Styles};
`

export const Text2 = styled.p`
  ${Text2Styles};

  ${withStrong('black')};
`

export const Text3Styles = css`
  font-weight: ${weights.semibold};
  color: ${colors.white};
  font-size: 8px;

  ${mediaUp('lg')} {
    font-size: 12px;
  }
`

export const Text3 = styled.p`
  ${Text3Styles};

  ${withStrong('black')};
`

export const Link1 = styled.a`
  ${focusVisible};
  ${Text1Styles};
  ${underlineHover};
`

export const Link2 = styled.a`
  ${Text2Styles};
  color: ${colors.twilightBlue};

  ${underlineHover};
`

export const Link3 = styled.a`
  ${Text3Styles};
  text-decoration: underline;
`

export const FieldLabel = styled.span<{ visuallyHidden?: boolean }>`
  ${Text2Styles};
  margin-bottom: 4px;
  display: block;
  color: ${colors.blueyGrey};

  ${props => props.visuallyHidden && visuallyHidden}

  ${mediaUp('lg')} {
    margin-bottom: 12px;
  }
`

export const LegiaMedium = css`
  font-family: 'eurostile', sans-serif;
  font-weight: 500;
`

export const LegiaBlack = css`
  font-family: 'eurostile', sans-serif;
  font-weight: 900;
`

export const wysiwyg = css`
  ${Text2Styles};
  color: var(--neutral);

  a {
    color: var(--accentFirst);
    ${underlineHover};
  }

  strong,
  b {
    font-weight: ${weights.black};
  }

  ul {
    list-style: none;
  }

  li {
    ${Text1Styles};
    color: var(--main);
    padding-inline-start: 50px;
    margin-bottom: 20px;
    position: relative;
    display: block;

    &:last-child {
      margin-bottom: 60px;
    }

    &::before {
      content: '';
      position: absolute;
      background-color: var(--accentSecond);
      left: 0;
      top: 0.45em;
      width: 18px;
      height: 5px;
      border-radius: 5px;

      html[lang^='ar'] & {
        right: 0;
        left: unset;
      }

      ${mediaUp('lg')} {
        width: 34px;
        height: 8px;
        border-radius: 8px;
      }
    }

    ${mediaDown('lg')} {
      &:last-child {
        margin-bottom: 28px;
      }

      padding-inline-start: 25px;
      margin-bottom: 10px;
    }
  }

  .legia-warszawa & {
    ${LegiaMedium};
    color: var(--mainCustom);

    a {
      color: var(--accentFirstCustom);
    }

    li {
      ${LegiaMedium};
      color: var(--mainCustom);
    }

    li::before {
      background-color: var(--accentFirstCustom);
      border-radius: 0;
    }
  }
`
