import { forwardRef, InputHTMLAttributes, ReactNode } from 'react'

import Error from '@/components/form/Error'

import { Field, FieldLabel, FieldLabelWrapper } from './Field'
import { IconWrapper, InputComponent, InputWrapper } from './Input.styled'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  error?: string
  active?: boolean
  noMargin?: boolean
  hideLabel?: boolean
  hideError?: boolean
  children?: ReactNode
  icon?: ReactNode
  dataTestId?: string
  uppercase?: boolean
}

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    label,
    error = '',
    active = false,
    noMargin = false,
    hideLabel = false,
    hideError = false,
    children,
    icon,
    dataTestId,
    uppercase,
    ...inputAttrs
  } = props

  return (
    <Field noMargin={noMargin}>
      <FieldLabelWrapper>
        <FieldLabel visuallyHidden={hideLabel}>{label}</FieldLabel>
        <InputWrapper>
          <InputComponent
            ref={ref}
            invalid={!!error}
            active={active}
            data-test-id={dataTestId}
            uppercase={uppercase}
            {...inputAttrs}
          />
          {icon && <IconWrapper>{icon}</IconWrapper>}
        </InputWrapper>
      </FieldLabelWrapper>
      {children}
      {error && !hideError && <Error error={error} dataTestId={dataTestId} />}
    </Field>
  )
})

export default Input
