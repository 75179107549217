import * as Sentry from '@sentry/nextjs'

import axios from 'axios'

import {
  CreateSubscriptionData,
  CreateSubscriptionParams,
  CreateSubscriptionResponse,
  CustomizedClientsResponse,
  DetailsSubscriptionResponse,
  PaymentDetailsSubscriptionResponse
} from '@/service'

import { ApiPaths } from './apiPaths'

export const createSubscription = async ({
  data,
  params
}: {
  data: CreateSubscriptionData
  params: CreateSubscriptionParams
}) => {
  try {
    const { data: results } = await axios.post<CreateSubscriptionResponse>(
      ApiPaths.API_V1_SUBSCRIPTIONS,
      data,
      { params }
    )
    return results
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchCustomizedClients = async ({ id }: { id: string }) => {
  try {
    const { data: results } = await axios.get<CustomizedClientsResponse>(
      `${ApiPaths.API_V1_CUSTOMIZED_CLIENTS}/${id}/data`
    )
    return results
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const getPaymentDetails = async (id: string) => {
  try {
    const { data } = await axios.get<PaymentDetailsSubscriptionResponse>(
      `/api/v1/subscriptions/${id}/payment`
    )
    return data
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchSubscriptionDetails = async ({ id }: { id: string }) => {
  try {
    const { data: results } = await axios.get<DetailsSubscriptionResponse>(
      `/api/v1/subscriptions/${id}`
    )
    return results
  } catch (e) {
    Sentry.captureException(e)
  }
}
