import axios, { AxiosInstance } from 'axios'

export interface DiscountValidateResponse {
  uuid: string
  value: number
}

export interface DiscountValidateParams {
  scope: 'RESERVATIONS' | 'SUBSCRIPTIONS'
  planUuid?: string
}

export default (axios: AxiosInstance) => ({
  validate: (
    code: string,
    parkingUuid: string,
    params: DiscountValidateParams
  ) =>
    axios.get<DiscountValidateResponse>(
      `/api/v1/discount-codes/${code}/parking/${parkingUuid}/check`,
      { params }
    )
})
