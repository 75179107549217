import React, { useEffect, useRef, ReactNode, useState } from 'react'
import { createPortal } from 'react-dom'

import { css } from '@emotion/react'
import { rgba } from 'polished'

import { Grid, mediaColumns } from '@/styles/grid'
import { colors, zIndex, border } from '@/styles/theme'
import { mediaUp, mediaDown } from '@/styles/media'
import { closeButton } from '@/styles/mixins'

interface Props {
  show: boolean
  children: ReactNode
  onClose(): void
}

function Modal(props: Props) {
  const { show = false, onClose, children } = props
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    containerRef.current = document.querySelector('#modal-root')
  }, [])

  useEffect(() => {
    if (show) {
      setIsOpen(true)
    }
  }, [show])

  const handleCloseClick = () => {
    setIsOpen(false)
    setTimeout(() => onClose(), 500)
  }

  const modalContent = show && (
    <div css={[wrapper, isOpen && wrapperOpened]}>
      <Grid>
        <div css={[container, isOpen && containerOpened]}>
          {children}
          <button onClick={handleCloseClick} css={closeButtonWrapper} />
        </div>
      </Grid>
    </div>
  )

  return (
    <>
      {containerRef.current
        ? createPortal(modalContent, containerRef.current)
        : null}
    </>
  )
}

const wrapper = css`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${rgba(colors.twilightBlue, 0.06)};
  z-index: ${zIndex.modalWrapper};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  backdrop-filter: blur(2px);
  opacity: 0;
  transition: opacity 0.5s;

  @supports not (backdrop-filter: blur(2px)) {
    background: ${rgba(colors.twilightBlue, 0.45)};
  }

  ${mediaUp('lg')} {
    align-items: center;
  }
`

const wrapperOpened = css`
  opacity: 1;
`

const container = css`
  ${mediaColumns({ xs: [12, 0], lg: [8, 2] })};
  position: relative;
  justify-content: center;
  border-radius: 28px;
  box-shadow: ${border.shadow};
  background-color: ${colors.white};
  z-index: ${zIndex.modal};
  padding: 60px 20px;
  max-height: calc(100vh - 40px);
  align-items: safe center;
  overflow: auto;

  ${mediaDown('lg')} {
    transform: translate(0%, 100%);
    transition: transform 0.5s;
    margin: 0 -30px;
    width: calc(100% + 60px);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${mediaDown('md')} {
    margin: 0 -16px;
    width: calc(100% + 32px);
  }

  ${mediaUp('md')} {
    padding: 60px 30px;
  }

  ${mediaUp('lg')} {
    padding: 60px;
  }
`

const containerOpened = css`
  ${mediaDown('lg')} {
    transform: translate(0%, 0%);
  }
`

const closeButtonWrapper = css`
  ${closeButton};
  top: 24px;
  right: 19px;

  ${mediaUp('lg')} {
    top: 40px;
    right: 40px;
  }

  html[lang^='ar'] & {
    right: unset;
    left: 19px;

    ${mediaUp('lg')} {
      right: unset;
      left: 40px;
    }
  }
`

export default Modal
