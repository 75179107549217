import { AxiosInstance } from 'axios'

export interface SubscribeData {
  email: string
}

export default (axios: AxiosInstance) => ({
  subscribe: (data: SubscribeData) =>
    axios.post('/api/v1/newsletter/subscribers', data)
})
