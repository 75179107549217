import React from 'react'
import parse from 'html-react-parser'

import { Wrapper } from './Error.styled'

interface Props {
  error?: string
  className?: string
  dataTestId?: string
}

function Error(props: Props) {
  const { error, className, dataTestId } = props

  return (
    <Wrapper
      className={className}
      {...(dataTestId && { 'data-test-id': `${dataTestId}-error` })}
    >
      {error && parse(error)}
    </Wrapper>
  )
}

export default Error
