import { AxiosInstance } from 'axios'

export interface BlockingsParams {
  parkingUuid?: string
  startDateTimeBefore?: string
  startDateTimeAfter?: string
  endDateTimeBefore?: string
  endDateTimeAfter?: string
  scope?: string
}

export interface Blocking {
  uuid: string
  startDateTime: string
  endDateTime: string
  parkingUuid: string
  admission: {
    type: string
    scope: string[] | null
    plans: string[]
  }
}

export interface BlockingsResponse {
  content: Blocking[]
  number: number
  size: number
  totalElements: number
  pageable: {
    sort: {
      empty: boolean
      unsorted: boolean
      sorted: boolean
    }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalPages: number
  sort: {
    empty: boolean
    unsorted: boolean
    sorted: boolean
  }
  first: boolean
}

export default (axios: AxiosInstance) => ({
  all: async () => {
    const response = await axios.get<BlockingsResponse>('/api/v1/blockings')
    const dates = response.data.content.map(blocking => ({
      startDateTime: blocking.startDateTime,
      endDateTime: blocking.endDateTime
    }))
    return dates
  }
})
