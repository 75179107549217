import styled from '@emotion/styled'

import { heightButton, resetInput } from '@/styles/mixins'
import { border, colors, transitions } from '@/styles/theme'
import { InputTextStyles, LegiaMedium } from '@/styles/typography'
import { mediaUp } from '@/styles/media'

export const InputComponent = styled.input<{
  invalid: boolean
  active: boolean
  disabled?: boolean
  uppercase?: boolean
}>`
  ${resetInput};
  ${heightButton};
  ${InputTextStyles};

  border-radius: ${border.radius};
  box-shadow: ${border.shadow};
  background-color: ${props => (props.disabled ? `var(--tint)` : colors.white)};
  ${props => props.uppercase && 'text-transform: uppercase'};
  border: 1px solid
    ${props =>
      props.active
        ? `var(--accentFirst)`
        : props.invalid
        ? colors.darkCoral
        : 'transparent'};
  color: var(--main);
  transition: border-color ${transitions.base};
  width: 100%;

  &:focus {
    border-color: var(--accentFirst);
  }

  ${mediaUp('lg')} {
    border-width: 2px;
  }

  .legia-warszawa & {
    ${LegiaMedium};
    color: var(--mainCustom);
    border-radius: 0;

    border: 1px solid
      ${props =>
        props.active
          ? `var(--accentFirstCustom)`
          : props.invalid
          ? `var(--errorCustom)`
          : 'transparent'};

    &:focus {
      border-color: var(--accentFirstCustom);
    }

    ${mediaUp('lg')} {
      border-width: 2px;
    }
  }
`

export const InputWrapper = styled.span`
  display: block;
  position: relative;
`

export const IconWrapper = styled.span`
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);

  svg {
    display: block;
    width: 10px;
    fill: var(--neutral);

    ${mediaUp('lg')} {
      width: 16px;
    }
  }

  ${mediaUp('lg')} {
    right: 40px;
  }

  html[lang^='ar'] & {
    right: unset;
    left: 18px;

    ${mediaUp('lg')} {
      left: 40px;
    }
  }
`
