import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { rgba } from 'polished'

import { Url } from '@/utils/i18n/types'
import Link from '@/components/common/Link'
import Loader from '@/components/common/Loader'

import { border, colors, transitions, weights } from '@/styles/theme'
import {
  ButtonTextStyles,
  LegiaMedium,
  FontFamilyStyles
} from '@/styles/typography'
import { heightButton, focusVisible } from '@/styles/mixins'

export type ButtonStyles = 'darkBg' | 'green' | 'primaryLight' | 'disabled'

interface Props {
  children?: ReactNode
  text: string
  link?: Url
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  loading?: boolean
  fullWidth?: boolean
  style?: ButtonStyles
  className?: string
  onClick?(): void
  dataTestId?: string
  targetBlank?: boolean
}

export type ButtonProps = Props

function Button(props: Props) {
  const {
    children,
    text,
    link,
    type = 'button',
    disabled = false,
    loading = false,
    fullWidth = false,
    style,
    className,
    onClick,
    dataTestId,
    targetBlank
  } = props
  const linkProps: { href: Url } | null = link ? { href: link } : null
  const buttonStyles = [
    styles.base,
    loading && styles.loading,
    fullWidth && styles.fullWidth,
    style && styles[style]
  ]

  return (
    <>
      {linkProps ? (
        <Link {...linkProps} passHref>
          <a
            css={buttonStyles}
            className={className}
            data-test-id={dataTestId}
            {...(targetBlank && { target: '_blank' })}
          >
            {loading && <Loader />}
            {children && children}
            {text}
          </a>
        </Link>
      ) : (
        <button
          css={buttonStyles}
          type={type}
          className={className}
          disabled={disabled}
          onClick={onClick}
          data-test-id={dataTestId}
        >
          {loading && <Loader />}
          {children && children}
          {text}
        </button>
      )}
    </>
  )
}

const styles = {
  base: css`
    ${focusVisible};
    ${ButtonTextStyles};
    ${heightButton};

    position: relative;
    color: ${colors.white};
    border-radius: ${border.radius};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${weights.black};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;

    z-index: 0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--accentFirst);
      transition: opacity ${transitions.base},
        background-color ${transitions.base}, filter ${transitions.base};
      z-index: -1;
    }

    &[disabled] {
      &::after {
        background-color: ${colors.cloudyBlue};
      }
    }

    &:not([disabled]) {
      &:hover,
      &:focus-visible {
        &::after {
          filter: brightness(85%);
        }
      }
    }

    .legia-warszawa & {
      ${LegiaMedium};
      border-radius: 0;
      text-transform: uppercase;

      &::after {
        background-color: var(--accentFirstCustom);
      }
    }

    .datetime-modal & {
      ${FontFamilyStyles};
      font-weight: ${weights.black};
      border-radius: ${border.radius};
      text-transform: none;

      &::after {
        background-color: var(--accentFirst);
      }

      &:not([disabled]) {
        &:hover,
        &:focus-visible {
          &::after {
            background-color: ${colors.blueHover};
          }
        }
      }
    }
  `,
  darkBg: css`
    &::after {
      background-color: ${rgba(colors.white, 0.25)};
    }

    &:not([disabled]) {
      &:hover,
      &:focus-visible {
        &::after {
          background-color: ${rgba(colors.white, 0.5)};
        }
      }
    }
  `,
  primaryLight: css`
    color: var(--accentFirst);

    &::after {
      background-color: var(--accentFirst);
      opacity: 0.16;
    }

    &:not([disabled]) {
      &:hover,
      &:focus-visible {
        &::after {
          opacity: 0.28;
        }
      }
    }
  `,
  green: css`
    color: ${colors.twilightBlue};

    &::after {
      background-color: ${colors.greenishCyan};
    }

    &:not([disabled]) {
      &:hover,
      &:focus-visible {
        &::after {
          background-color: ${colors.greenishCyanHover};
        }
      }
    }

    &[disabled] {
      color: ${rgba(colors.twilightBlue, 0.5)};

      &::after {
        background-color: ${rgba(colors.greenishCyan, 0.5)};
      }
    }
  `,
  disabled: css`
    pointer-events: none;

    &::after {
      background-color: ${colors.cloudyBlue};
    }

    &:not([disabled]) {
      &:hover,
      &:focus-visible {
        &::after {
          background-color: ${colors.cloudyBlue};
        }
      }
    }
  `,
  loading: css`
    pointer-events: none;
    color: transparent !important;

    &::after {
      background-color: var(--accentFirst);
    }

    .legia-warszawa & {
      &::after {
        background-color: var(--accentFirstCustom);
      }
    }
  `,
  fullWidth: css`
    width: 100%;
  `
}

export default Button
