import { AxiosInstance } from 'axios'

import { ApiPaths } from '@/utils/apiPaths'

export interface CategoryUrlSlugs {
  pl: string
  en: string
  de: string
  ar: string
}

export interface MockedFaqItem {
  title: string
  content: string
}

export interface FaqItem {
  category: string
  slug: string
  categoryUrlSlug: string
  title: string
  content: string
}

export interface CategoryFaqItem {
  title: string
  categoryUrlSlug: string
  categoryUrlSlugs: CategoryUrlSlugs
  icon: string
}

export interface AllFaqParams {
  categoryUrlSlug?: string
  lang?: string
  forHomePage?: boolean
  query?: string
}

export interface CategoriesFaqParams {
  lang?: string
  countryCode?: string
}

export interface AllFaqResponse {
  items: FaqItem[]
}

export interface CategoriesFaqResponse {
  items: CategoryFaqItem[]
}

export type FaqCategory = {
  category: string
  categoryUrlSlug: string
  icon: string
}

export type FaqContent = {
  title: string
  content: string
}

export type FaqData = {
  categories: Record<string, FaqCategory>
  content: Record<string, FaqContent[]>
}

export const Faq = (axios: AxiosInstance) => ({
  categories: (params: CategoriesFaqParams) =>
    axios.get<CategoriesFaqResponse>(ApiPaths.API_V1_FAQ_CATEGORIES, { params })
})
