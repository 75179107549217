import type React from 'react'
import NextLink, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

import { useMarket } from '@/utils/multi-markets/context'
import { toTranslatedLink } from '@/utils/i18n/enhanced-router'

function Link(props: React.PropsWithChildren<LinkProps>) {
  const router = useRouter()
  const { locale, defaultLocale } = useMarket()
  const { href, as, ...rest } = props

  const link = toTranslatedLink(
    href,
    as,
    router.pathname,
    locale,
    defaultLocale
  )

  return <NextLink href={link.href} as={link.as} locale={false} {...rest} />
}

export default Link
