import { useQuery } from '@tanstack/react-query'

import { ApiPaths } from '@/utils/apiPaths'

import { fetchCustomizedClients } from '@/utils/subscriptionsService'

export const useFetchCustomizedClients = ({ id }: { id: string }) => {
  const { data: clientDetails, isLoading } = useQuery({
    queryKey: [ApiPaths.API_V1_CUSTOMIZED_CLIENTS, id, 'data'],
    queryFn: () =>
      fetchCustomizedClients({
        id
      }),
    enabled: Boolean(id)
  })

  return {
    clientDetails,
    isLoading
  }
}
