/**
 * Find orphans and add hard space
 */
export function unOrphan(text: string): string {
  return text.replace(/(\s+)((?:[\S][\s]+)+\S{2,})/gi, x => {
    const text = x.split(' ')
    text.shift()
    return ' ' + text.join('&nbsp;')
  })
}
