import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { breakpoints, Breakpoints, mediaUp } from './media'

export type Media = Partial<Record<Breakpoints, number[]>>

export const gridGutter: Record<Breakpoints, number> = {
  xs: 8,
  sm: 8,
  md: 12,
  lg: 20,
  xl: 40
}

export const pagePadding: Record<Breakpoints, number> = {
  xs: 16,
  sm: 16,
  md: 30,
  lg: 50,
  xl: 80
}

export const gridMaxWidth = '1440px'
export const gridColumns = 12

export const columnWidth = (columns: number, property: string) => {
  return Object.keys(breakpoints)
    .map(key => {
      const breakpoint = key as Breakpoints
      const col = `((100vw - ${pagePadding[breakpoint] * 2}px - ${
        gridGutter[breakpoint] * 11
      }px) / 12)`
      const gap = `(${(columns - 1) * gridGutter[breakpoint]}px)`
      return css`
        ${mediaUp(breakpoint)} {
          ${property} calc((${col} * ${columns}) + ${gap});
        }
      `
    })
    .flat()
}

export const createGrid = () => {
  return Object.keys(breakpoints)
    .map(key => {
      const breakpoint = key as Breakpoints
      return css`
        ${mediaUp(breakpoint)} {
          grid-gap: 0 ${gridGutter[breakpoint]}px;
          padding-right: ${pagePadding[breakpoint]}px;
          padding-left: ${pagePadding[breakpoint]}px;
        }
      `
    })
    .flat()
}

export const column = (columns: any, offset: number) => {
  const isAll = columns === 'all'
  const start = isAll ? 1 : offset + 1
  const end = isAll ? gridColumns : start + columns
  return `grid-column: ${start} / ${end};`
}

export const mediaColumns = (columns: Media) => {
  return Object.entries(columns)
    .map(el => {
      const breakpoint = el[0] as Breakpoints
      const value = el[1] as number[]
      return css`
        ${mediaUp(breakpoint)} {
          ${column(value[0], value[1])}
        }
      `
    })
    .flat()
}

export const Grid = styled.div<{ center?: boolean }>`
  display: grid;
  width: 100%;
  max-width: ${gridMaxWidth};
  margin: 0 auto;
  grid-template-columns: repeat(${gridColumns}, 1fr);
  ${props => props.center && `align-items: center;`}

  ${createGrid()};
`
