import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { colors } from '@/styles/theme'
import { mediaUp } from '@/styles/media'
import { H1, H2, LegiaBlack } from '@/styles/typography'
import { titleAnimation } from '@/styles/mixins'

const title = (props: {
  light?: boolean
  primary?: boolean
  animated?: boolean
  highlight?: boolean
}) => css`
  ${props.animated && titleAnimation};
  ${props.light && `color: ${colors.white};`}
  ${!props.primary && `text-align: center;`}

  strong {
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      top: 1em;
      z-index: -1;
      left: 0;
      width: 100%;
      background-color: var(--accentSecond);
      height: 5px;
      border-radius: 3px;

      ${mediaUp('lg')} {
        height: 8px;
        border-radius: 4px;
      }

      html[lang^='ar'] & {
        top: 1.3em;
        left: unset;
        right: 0;
      }
    }
  }

  ${props.highlight &&
  `
    strong {
      color: ${colors.darkSkyBlue};
      &::after {
        content: none;
      }
    }
  `}

  .legia-warszawa & {
    ${LegiaBlack};
    color: var(--mainCustom);

    strong::after {
      background-color: var(--accentFirstCustom);
      border-radius: 0;
    }
  }
`

export const PrimaryTitle = styled(H1)`
  ${title}
`

export const SecondaryTitle = styled(H2)`
  ${title}
`
