import { AxiosInstance } from 'axios'

import {
  CreateSubscriptionData,
  CreateSubscriptionParams,
  CreateSubscriptionResponse,
  PatchSubscriptionData,
  PatchSubscriptionParams,
  PaymentDetailsSubscriptionResponse,
  AvailabilitySubscriptionParams,
  AvailabilitySubscriptionResponse
} from './subscriptions'

export default (axios: AxiosInstance) => ({
  create: (data: CreateSubscriptionData, params: CreateSubscriptionParams) =>
    axios.post<CreateSubscriptionResponse>('/api/v1/subscriptions', data, {
      params
    }),
  createFree: (
    data: CreateSubscriptionData,
    params: CreateSubscriptionParams
  ) =>
    axios.post<CreateSubscriptionResponse>(
      '/api/v1/subscriptions/free-subscription',
      data,
      {
        params
      }
    ),
  patch: (
    id: string,
    data: PatchSubscriptionData,
    params: PatchSubscriptionParams
  ) => axios.patch(`/api/v1/subscriptions/${id}`, data, { params }),
  paymentDetails: (id: string) =>
    axios.get<PaymentDetailsSubscriptionResponse>(
      `/api/v1/subscriptions/${id}/payment`
    ),
  availability: (params: AvailabilitySubscriptionParams) =>
    axios.get<AvailabilitySubscriptionResponse>(
      '/api/v1/subscriptions/availability',
      { params }
    )
})
