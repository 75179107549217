import { Colors } from '@/styles/theme'

import { Loader } from './Loader.styled'

interface Props {
  color?: Colors
  size?: 'small' | 'medium' | 'large'
  center?: boolean
}

function LoaderComponent(props: Props) {
  const { color = 'white', size = 'medium', center = true } = props

  return <Loader color={color} size={size} center={center} />
}

export default LoaderComponent
