import styled from '@emotion/styled'

import { mediaUp } from '@/styles/media'

export { FieldLabel } from '@/styles/typography'

export const Field = styled.div<{ noMargin?: boolean }>`
  width: 100%;
  position: relative;
  margin-bottom: ${props => (props.noMargin ? '0' : '18px')};

  ${mediaUp('lg')} {
    margin-bottom: ${props => (props.noMargin ? '0' : '40px')};
  }
`

export const FieldLabelWrapper = styled.label`
  width: 100%;
  position: relative;
  display: block;
`
